import React, { useContext } from 'react'
import Sentry from 'sentry.config'
import AnonymousUser from 'models/AnonymousUser'
import { UserProfileContext } from './UserProfileProvider'
import { Primitive } from 'react-hook-form'

interface SentryScope {
  writeSentryError: (error: any, tags?: any, message?: string) => void
}

const writeSentryError = (
  error: any,
  tags?: Record<string, Primitive>,
  message?: string,
): void => {
  if (tags != null) {
    Sentry.setTags(tags)
  }
  Sentry.captureMessage(message ?? '')
  Sentry.captureException(error, { level: 'error' })
}

export const SentryScopeContext = React.createContext<SentryScope>({
  writeSentryError,
})

const SentryScopeProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { user } = useContext(UserProfileContext)

  Sentry.configureScope((scope) => {
    if (user instanceof AnonymousUser || user.key == null) scope.clear()
    else {
      scope.setUser({
        id: user.key,
        email: user.profile.email,
      })
      scope.setTags({
        user_type: user.constructor.name,
      })
    }
  })

  return (
    <SentryScopeContext.Provider value={{ writeSentryError }}>
      {children}
    </SentryScopeContext.Provider>
  )
}

export default SentryScopeProvider
