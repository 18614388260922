export type NotificationType =
  | 'LegalMatterDocumentUpdated'
  | 'LegalMatterDocumentDeleted'
  | 'LegalMatterCreated'
  | 'LegalMatterAssigned'
  | 'LegalMatterAccepted'
  | 'LegalMatterClaimed'
  | 'LegalMatterWithdrawn'
  | 'LegalMatterRejected'
  | 'LegalMatterCancelled'
  | 'LegalMatterClosed'
  | 'TaskCreated'
  | 'TaskCompleted'
  | 'CalendarEventCreated'
  | 'CalendarEventUpdated'
  | 'CalendarEventDeleted'
  | 'SubscriptionDeleted'
  | 'LegalMatterShareRejected'
  | 'LegalMatterShared'

interface NotificationInfo {
  description?: string
  message: string
}

export const Notifications: Partial<
  Record<NotificationType, NotificationInfo>
> = {
  TaskCreated: { message: 'A new task has been created.' },
  LegalMatterDocumentUpdated: {
    message: 'A document has been attached to the legal matter.',
  },
  TaskCompleted: { message: 'A task has been marked completed.' },
  CalendarEventCreated: { message: 'A new calendar event has been created.' },
  CalendarEventUpdated: { message: 'A calendar event has been updated.' },
  CalendarEventDeleted: { message: 'A calendar event has been deleted.' },
  LegalMatterWithdrawn: {
    message: 'The lawyer has withdrawn from the legal matter.',
  },
  LegalMatterRejected: { message: 'The legal matter has been rejected.' },
  LegalMatterAssigned: {
    message: 'A legal matter has been assigned by the firm.',
  },
  LegalMatterClaimed: {
    message: 'Legal matter has been assigned to a lawyer.',
  },
  LegalMatterCancelled: {
    message: 'Legal matter has been cancelled by the subscriber.',
  },
  LegalMatterClosed: { message: 'Legal matter has been closed by the lawyer.' },
  LegalMatterAccepted: {
    message: 'A lawyer has accepted to take up the legal matter.',
  },
  LegalMatterCreated: { message: 'A new legal matter has been created.' },
  SubscriptionDeleted: {
    message: 'The subscriber no longer has an active subscription.',
  },
  LegalMatterShareRejected: {
    message: 'A firm user has removed himself from the legal matter',
  },
}
