import { useState } from 'react'
import Login from '../organisms/Login'
import Box from '@mui/material/Box'
import {
  signInWithGoogle,
  signInWithEmail,
  createAccountWithEmailAndPassword,
} from 'firebaseConfig'
import { AuthResponseStatus } from '../../../@types/Auth'
import { enqueueSnackbar } from 'notistack'

export interface Error {
  message: string
  isError: boolean
}

const AuthPage = (): JSX.Element => {
  const [error, setError] = useState<Error | undefined>({
    message: '',
    isError: false,
  })

  const onSignInOrSignUpWithEmailAndPassword = async (data): Promise<void> => {
    const signInResult = await signInWithEmail(data)

    if (signInResult.status !== AuthResponseStatus.successful) {
      if (signInResult.status === AuthResponseStatus.userNotFound) {
        const signUpResult = await createAccountWithEmailAndPassword(data)

        if (signUpResult.status !== AuthResponseStatus.successful) {
          setError({
            message: signUpResult.message,
            isError: true,
          })
        }
      } else {
        setError({
          message: signInResult.message,
          isError: true,
        })
        enqueueSnackbar('Login failed, please try again', {
          variant: 'error',
        })
      }
      // If no errors in state then redirect to requested url or root
      if (!error?.isError) {
        enqueueSnackbar('You have been signed in', {
          variant: 'success',
        })
      }
    }
  }

  const onSignInWithGoogle = (): void => {
    void signInWithGoogle().then((res) => {
      if (res.status !== AuthResponseStatus.successful) {
        setError({
          message: res.message,
          isError: true,
        })
      }
      if (!error?.isError) {
        enqueueSnackbar('You have been signed in', {
          variant: 'success',
        })
      }
    })
  }

  return (
    <Box
      sx={{
        margin: 'auto',
      }}
      maxWidth="sm"
    >
      <Login
        onSubmitLoginForm={onSignInOrSignUpWithEmailAndPassword}
        onGoogleSignIn={onSignInWithGoogle}
        errors={error}
      />
    </Box>
  )
}

export default AuthPage
