import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

interface Props {
  message: string
}

const ApplicationError = ({ message }: Props): JSX.Element => {
  return (
    <Alert severity="error">
      <AlertTitle>An application error has occurred</AlertTitle>
      {message}
    </Alert>
  )
}

export default ApplicationError
