import React, { useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes'
import {
  FirmUserType,
  type MutationError,
  type LawyerUser,
  LegalMatterStatus,
  CursorDirection,
  FirmLegalMatterDetailDocument,
  AssigneeFirmUserFragment,
} from '../../../@types/generated/graphql'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import LegalMatterSidebar from 'view/components/organisms/LegalMatterSidebar'
import ClientContactInformation from 'view/components/molecules/ClientContactInformation'
import TwoColumnLayout from 'view/components/templates/TwoColumnLayout'
import AssignLawyerCard from '../molecules/AssignLawyerCard'
import AnimatedList from '../molecules/AnimatedList'
import { getPastDate } from '../../../utils/dateUtils'
import DropdownPeriodSelector from '../atoms/DropdownPeriodSelector'
import { Button, Divider, Tab, TextareaAutosize } from '@mui/material'
import IntakeFormStep from '../forms/IntakeFormStep'
import CalendarEventListItem from '../organisms/CalendarEventListItem'
import { labelSlugs } from 'constants/index'
import Access from '../molecules/Access'
import { resolveSubscriberName } from 'utils/StringFormatter'
import { enqueueSnackbar } from 'notistack'
import { LegalMatterLoading } from '../molecules/LoadingSkeletons'
import { FirmLegalMatterStatusLabels } from 'utils/StatusMapper'
import model from 'models'
import LegalMatterRequestAction from '../molecules/LegalMatterRequestAction'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from '../molecules/ConfirmationDialog'
import { type Errors } from '../../../@types/DialogControlType'
import useLegalMatter from 'utils/useLegalMatter'
import useCurrentUser from 'utils/useCurrentUser'
import FirmUserTaskListing from '../data/FirmUserTaskListing'
import ApplicationError from '../molecules/ApplicationError'

type TabPanelType = 'INTAKEFORM' | 'LEGALMATTER'

interface DialogControlProps extends ConfirmationDialogProps {
  textBoxContent?: string
  errors?: Errors
}

const FirmLegalMatter = (): JSX.Element | null => {
  const { legalMatterKey } = useParams()

  const navigate = useNavigate()
  const { user, loading: userLoading } = useCurrentUser()
  const [selectedTab, setSelectedTab] = useState<TabPanelType>('LEGALMATTER')
  const [eventsPeriod, setEventsPeriod] = useState<number>(7)
  const [eventsPeriodDateTime, setEventsPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(eventsPeriod, 'UTC'))
  const [tasksPeriod, setTasksPeriod] = useState<number>(7)
  const [tasksPeriodDateTime, setTasksPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(tasksPeriod, 'UTC'))
  const [currentlyAssigningLawyerKey, setCurrentlyAssigningLawyerKey] =
    useState<string | null>(null)

  const dialogControlIntialState: DialogControlProps = {
    open: false,
    title: <></>,
    content: <></>,
    actions: <></>,
    textBoxContent: undefined,
    errors: {
      message: undefined,
    },
  }

  const [dialogControlProps, setDialogControlProps] = useState(
    dialogControlIntialState,
  )

  const { data, networkStatus } = useQuery(FirmLegalMatterDetailDocument, {
    variables: {
      key: legalMatterKey,
      since: eventsPeriodDateTime,
      lastUpdatedSince: tasksPeriodDateTime,
      userType: FirmUserType.LawyerUser,
      calendarLimit: 3,
      calendarCursor: null,
      calendarCursorDirection: CursorDirection.Next,
      documentsLimit: 3,
      documentsCursor: null,
      documentsCursorDirection: CursorDirection.Next,
    },
    notifyOnNetworkStatusChange: true,
    skip: userLoading,
  })
  const {
    assignLegalMatter,
    acceptReferredLegalMatter,
    rejectReferredLegalMatter,
    legalMatterLoading,
  } = useLegalMatter()

  if (userLoading || networkStatus == NetworkStatus.loading) {
    return <LegalMatterLoading />
  }

  if (data?.legalMatters?.edges?.length === 0) {
    return <ApplicationError message="This legal matter does not exist." />
  }

  const legalMatter = data?.legalMatters?.edges?.[0]?.node
  const lawyersList =
    data?.firmUsers?.edges?.map(
      (edge) => edge.node as AssigneeFirmUserFragment,
    ) ?? []

  if (legalMatter === undefined) {
    // TODO Return error page
    return null
  }

  const legalMatterModel = new model.LegalMatter(legalMatter)

  const handleLegalMatterAssignment = (lawyerKey: string): void => {
    setCurrentlyAssigningLawyerKey(lawyerKey)
    void assignLegalMatter({
      variables: { legalMatterKey, lawyerKey },
      refetchQueries: ['FirmLegalMatterDetail'],
      onCompleted: (data) => {
        if (data.legalMatterAssign.success != null) {
          enqueueSnackbar('The legal matter has been successfully assigned.', {
            variant: 'success',
          })
          navigate(routes.firmLegalMatterListing.path)
        } else {
          enqueueSnackbar(data.legalMatterAssign.message, { variant: 'error' })
        }
      },
    })
  }

  const handleEventPeriodChange = (value: number): void => {
    setEventsPeriod(value)
    setEventsPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const handleTasksPeriodChange = (value: number): void => {
    setTasksPeriod(value)
    setTasksPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newSelectedTab: TabPanelType,
  ): void => {
    setSelectedTab(newSelectedTab)
  }

  const onClickAcceptReferredIn = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm acceptance
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            You agree to accept this referred legal matter
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('acceptReferrMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Acceptance
          </Button>
        </>
      ),
    })
  }

  const onClickRejectReferral = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm rejection of referred legal matter
          </Typography>
        </>
      ),
      content: (
        <>
          {dialogControlProps.errors?.message !== undefined &&
            dialogControlProps.errors?.message !== '' && (
              <Alert variant="outlined" severity="error">
                {dialogControlProps.errors.message}
              </Alert>
            )}
          <Typography>
            Please state why are you rejecting this legal matter:
          </Typography>
          <TextareaAutosize
            minRows={10}
            style={{ width: '97%' }}
            onChange={(e) => {
              setDialogControlProps((prevState) => ({
                ...prevState,
                textBoxContent: e.target.value,
              }))
            }}
          />
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('rejectReferrMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Rejection
          </Button>
        </>
      ),
    })
  }

  const handleDialogActionConfirm = (action: string): void => {
    switch (action) {
      case 'acceptReferrMatter':
        void acceptReferredLegalMatter({
          variables: {
            legalMatterKey,
          },
          refetchQueries: ['FirmLegalMatterDetail'],
          onCompleted: (data) => {
            if (data.legalMatterReferralAccept.success != null) {
              enqueueSnackbar(
                'The referred legal matter has been accepted and is now on status of UNASSIGNED',
                {
                  variant: 'success',
                },
              )
              setDialogControlProps(dialogControlIntialState)
              // navigate(routes.firmLegalMatterListing.path)
            } else {
              enqueueSnackbar(data.legalMatterReferralAccept.message, {
                variant: 'error',
              })
            }
          },
        })
        break
      case 'rejectReferrMatter':
        setDialogControlProps((currentDialogControlProps) => {
          if (
            currentDialogControlProps.textBoxContent !== undefined &&
            currentDialogControlProps.textBoxContent !== ''
          ) {
            void rejectReferredLegalMatter({
              variables: {
                legalMatterKey,
                rejectionReason: currentDialogControlProps.textBoxContent,
              },
              refetchQueries: ['FirmLegalMatterDetail'],
              onCompleted: (data) => {
                if (data.legalMatterReferralReject.success != null) {
                  setDialogControlProps(dialogControlIntialState)
                  navigate(routes.firmLegalMatterListing.path, {
                    state: 'REJECTED',
                  })
                } else {
                  const errors =
                    data?.legalMatterReferralReject as MutationError
                  setDialogControlProps({
                    ...dialogControlProps,
                    errors: {
                      message: errors?.message,
                    },
                  })
                }
              },
            })
            return currentDialogControlProps
          } else {
            return {
              ...currentDialogControlProps,
              errors: {
                message: 'You have to state a reason first.',
              },
            }
          }
        })
        break
      default:
    }
  }

  return (
    <>
      <Access
        requires={legalMatterModel.statusMatches(LegalMatterStatus.ReferredIn)}
        fallback={null}
      >
        <LegalMatterRequestAction
          requestMessage="This legal matter has been referred to you firm, please accept or reject this matter."
          textButtonOptionA="Accept Request"
          textButtonOptionB="Decline Request"
          onClickOptionAccept={onClickAcceptReferredIn}
          onClickOptionDecline={onClickRejectReferral}
        />
      </Access>
      <Box>
        {legalMatterLoading ? (
          <Alert severity="info">Assigning the legal matter ...</Alert>
        ) : null}
      </Box>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Firm Tab List">
            <Tab
              label={
                legalMatterModel.assignmentInProgress()
                  ? labelSlugs.LEGAL_MATTER_ASSIGNMENT_TAB
                  : labelSlugs.LEGAL_MATTER_INFO_TAB
              }
              value="LEGALMATTER"
            />
            <Tab
              label={labelSlugs.LEGAL_MATTER_INTAKE_TAB}
              value="INTAKEFORM"
            />
          </TabList>
        </Box>
        <TabPanel value="INTAKEFORM">
          <IntakeFormStep
            readonly={true}
            intakeDataRef={legalMatter?.intakeDataRefs?.[0]}
          ></IntakeFormStep>
        </TabPanel>
        <TabPanel value="LEGALMATTER">
          <TwoColumnLayout>
            <LegalMatterSidebar
              legalMatterFragment={legalMatter}
              legalMatterStatusLabels={FirmLegalMatterStatusLabels}
              user={user}
            >
              <ClientContactInformation
                subscriberName={resolveSubscriberName(
                  legalMatter?.subscriber?.firstName,
                  legalMatter?.subscriber?.lastName,
                )}
                phone={legalMatter?.subscriber?.phone ?? undefined}
                email={legalMatter?.subscriber?.email ?? undefined}
              />
            </LegalMatterSidebar>
            <Box mb={4}>
              <Access requires={legalMatterModel.assignmentInProgress()}>
                {lawyersList !== undefined && (
                  <Box my={2}>
                    <Typography variant="h5" gutterBottom>
                      Assign an Attorney
                    </Typography>
                    {lawyersList?.map((lawyer, idx: number) => (
                      <AssignLawyerCard
                        key={`lawyer-${idx}`}
                        lawyer={lawyer as LawyerUser}
                        isAssigning={
                          currentlyAssigningLawyerKey === lawyer.key
                            ? legalMatterLoading
                            : undefined
                        }
                        onClickAssign={() => {
                          handleLegalMatterAssignment(lawyer.key)
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Access>
              <Access requires={!legalMatterModel.assignmentInProgress()}>
                <Typography variant="h2" gutterBottom>
                  Consultations
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  xs={12}
                  spacing={2}
                  mb={1}
                >
                  <Grid item>Showing Events from:</Grid>
                  <Grid item>
                    <DropdownPeriodSelector
                      value={eventsPeriod.toString()}
                      onChangeValue={handleEventPeriodChange}
                    ></DropdownPeriodSelector>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, mb: 2 }} />
                {legalMatterModel.get('calendarEvents') != null ? (
                  <AnimatedList animateItemKey={null}>
                    {legalMatterModel
                      ?.get('calendarEvents')
                      .edges.map((event) => (
                        <CalendarEventListItem
                          key={event.node.key}
                          calendarEvent={event.node}
                        />
                      ))}
                  </AnimatedList>
                ) : (
                  <Typography variant="h4">
                    {labelSlugs.NO_COSULTATIONS_AVAILABLE}
                  </Typography>
                )}
                <Box my={2}>
                  <Divider sx={{ mb: 2 }} />
                  <FirmUserTaskListing legalMatterKey={legalMatterKey} />
                </Box>
              </Access>
            </Box>
          </TwoColumnLayout>
        </TabPanel>
      </TabContext>
      <ConfirmationDialog
        open={dialogControlProps.open}
        onClose={() => {
          setDialogControlProps(dialogControlIntialState)
        }}
        title={dialogControlProps.title}
        content={dialogControlProps.content}
        actions={dialogControlProps.actions}
        errorMessage={dialogControlProps?.errors?.message}
      />
    </>
  )
}

export default FirmLegalMatter
