import {
  JsonFormType,
  type MutationError,
} from '../../../@types/generated/graphql'
import IntakeFormStep from '../forms/IntakeFormStep'
import { type IntakeMutationProps } from '../../../@types/JsonSchemaForm'
import { useNavigate } from 'react-router-dom'
import config from 'config'
import { routes } from 'routes'
import { useContext, useState } from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import useSubscriberUser from 'utils/useSubscriberUser'
import { useSnackbar } from 'notistack'

const SubscriberRegistration = (): JSX.Element => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useContext(UserProfileContext)
  const { createSubscriberUser, subscriberUserLoading } = useSubscriberUser()
  const [errors, setErrors] = useState<MutationError | undefined>(undefined)
  const [submissionSucceeded, setSubmissionSucceeded] = useState<boolean>(false)

  const onSubmit = ({ formData }: IntakeMutationProps): void => {
    void createSubscriberUser({
      variables: {
        data: formData,
      },
      onCompleted: (data) => {
        if (data?.subscriberCreate?.__typename === 'MutationError') {
          setErrors(data.subscriberCreate as MutationError)
          enqueueSnackbar('Error submitting form', { variant: 'error' })
        } else {
          enqueueSnackbar('Form submitted successfully', { variant: 'success' })
          setSubmissionSucceeded(true)
          navigate(routes.checkoutManageProducts.path)
        }
      },
    })
  }

  return (
    <>
      <Alert severity="info" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-state-notice">
          If you don't see your state, please note that LegalFix is only
          available in a limited number of states at this time. Contact LegalFix
          team with any questions.
        </Typography>
        {
          <Button
            component={Link}
            variant="contained"
            href={`mailto:${config.legalPlansContactEmail}`}
            data-testid="signup-contact-button"
          >
            Contact LegalFix
          </Button>
        }
      </Alert>
      <IntakeFormStep
        formType={JsonFormType.UserSubscription}
        onSubmit={onSubmit}
        submissionErrors={errors}
        submissionSuccess={submissionSucceeded}
        submitting={subscriberUserLoading}
        presetData={{
          userInfo: {
            email: user.profile.email,
          },
        }}
        presetUiSchema={{
          userInfo: {
            email: {
              'ui:readonly': true,
            },
          },
        }}
      />
    </>
  )
}

export default SubscriberRegistration
