import { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

interface Item {
  /** Text that will appear to the user **/
  name: string
  /** link href **/
  path: string
}

export interface Props {
  /** The item that will appear in the profile placeholder of the Navbar **/
  profile?: Item
  /** Callback function called when the sign out item of the profile menu **/
  onSignOut: () => void
  /** Username or profile picture to display when signed in **/
  avatarSrc: string | null
  hasSubscriberProfile?: boolean
}

const AvatarMenu = ({
  onSignOut,
  avatarSrc,
  profile,
  hasSubscriberProfile,
}: Props): JSX.Element => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }

  const getNameLetterOrPicture = (src: string | null): any => {
    return {
      sx: { width: 40, height: 40 },
      src,
      children: src?.split(' ')[0][0],
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{ p: 0, ml: 6, mr: 4 }}
        data-testid="navbar-avatar"
        aria-label="Open Sign in Options"
      >
        <Avatar {...getNameLetterOrPicture(avatarSrc)} />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        data-testid="navbar-profileMenu"
      >
        <MenuItem component="a" href={profile?.path}>
          <Typography textAlign="center">{profile?.name}</Typography>
        </MenuItem>
        <MenuItem component="a" href="/profile">
          <Typography textAlign="center">My Profile</Typography>
        </MenuItem>
        {hasSubscriberProfile !== undefined && hasSubscriberProfile && (
          <MenuItem component="a" href="/profile/subscription">
            <Typography textAlign="center">My Subscriptions</Typography>
          </MenuItem>
        )}

        {onSignOut !== null && (
          <MenuItem onClick={onSignOut} data-testid="signOut-button">
            <Typography textAlign="center">Sign out</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AvatarMenu
