import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
  createHttpLink,
  TypePolicies,
  from,
  gql,
  Reference,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { type DocumentNode } from 'graphql'
import config from 'config'
import globalErrorHandler from 'utils/graphqlErrorHandler'
import { withToken } from './useFirebaseAuth'
import { AsStoreObject } from '@apollo/client/utilities'

interface TypedPoliciesSchema {
  typeDefs?: DocumentNode
  typePolicies: TypePolicies
}

export const parseAliasedFieldName = (fieldName: string): any => {
  const jsonVars = fieldName.substring(
    fieldName.indexOf('{'),
    fieldName.lastIndexOf('}') + 1,
  )
  return JSON.parse(jsonVars)
}

export const edgesInclude = (edges, ref: Reference | undefined): boolean =>
  ref != null && edges.some((edge) => edge.node.__ref === ref.__ref)

const makeFullName = (_, { readField }): string | undefined => {
  const firstName = readField('firstName')
  const lastName = readField('lastName')
  const fullName = `${firstName !== undefined ? firstName + ' ' : ''}${
    lastName ?? ''
  }`
  return fullName.length > 0 ? fullName : undefined
}

export const makeEmptyData = () => {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

const mergeMore = (existing = makeEmptyData(), incoming: any = []) => {
  const edges = [...existing.edges, ...incoming.edges]
  return {
    ...existing,
    ...incoming,
    edges,
  }
}

const queryExtensions = gql`
  extend type Query {
    readLegalMatters: [LegalMatter!]!
  }
`

export const schema: TypedPoliciesSchema = {
  typeDefs: queryExtensions,
  typePolicies: {
    CalendarEvent: {
      keyFields: ['key'],
    },
    Document: {
      keyFields: ['key'],
    },
    Firm: {
      keyFields: ['key'],
    },
    FirmUser: {
      keyFields: ['key'],
      fields: {
        fullName: {
          read: makeFullName,
        },
      },
    },
    LawyerUser: {
      keyFields: ['key'],
      fields: {
        fullName: {
          read: makeFullName,
        },
      },
    },
    LegalMatter: {
      keyFields: ['key'],
      fields: {
        calendarEvents: {
          keyArgs: ['since'],
          merge: mergeMore,
        },
        documents: {
          keyArgs: false,
          merge: mergeMore,
        },
        notes: {
          keyArgs: false,
          merge: mergeMore,
        },
        tasks: {
          keyArgs: ['lastUpdatedSince', 'assignedToIn', 'assignedToNotIn'],
          merge: mergeMore,
        },
      },
    },
    LegalMatterKind: {
      keyFields: ['key'],
    },
    Note: {
      keyFields: ['key'],
    },
    SubscriberUser: {
      keyFields: ['key'],
      fields: {
        fullName: {
          read: makeFullName,
        },
      },
    },
    Task: {
      keyFields: ['key'],
    },
    Query: {
      fields: {
        legalMatters: {
          keyArgs: ['key', 'assignedLawyerRef', 'status', 'ratings', 'limit'],
          merge: mergeMore,
        },
      },
    },
  },
}

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: config.legalPlansApiBaseUrl,
    })
    const cache = new InMemoryCache({
      typePolicies: schema.typePolicies,
    })

    return new ApolloClient({
      link: from([onError(globalErrorHandler), withToken.concat(httpLink)]),
      connectToDevTools: import.meta.env.MODE === 'development',
      cache,
      typeDefs: schema.typeDefs,
    })
  }, [])

  return client
}

export const getCacheId = (typename: string, key: string): string =>
  `${typename}:{"key":"${key}"}`
