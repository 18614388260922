import * as Sentry from '@sentry/react'
import config from 'config'

Sentry.init({
  dsn: config.sentryDSN,
  enabled: import.meta.env.MODE !== 'development',
  environment: config.sentryEnvironment,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: config.version,
})

export default Sentry
