import { useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import DropdownPeriodSelector from 'view/components/atoms/DropdownPeriodSelector'

export interface TaskFilters {
  context: string
  daysSinceUpdated: number
}

interface Props {
  tabs: Record<string, string>
  daysSinceUpdated: number
  onChangeFilters: (filters: TaskFilters) => void
}

const TaskFilter = ({
  daysSinceUpdated,
  tabs,
  onChangeFilters,
}: Props): JSX.Element => {
  const tabIds = Object.keys(tabs)
  const [currentTab, setCurrentTab] = useState<string>(tabIds[0])

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={currentTab}
        onChange={(event: React.SyntheticEvent, newValue: string) => {
          setCurrentTab(newValue)
          onChangeFilters({ context: newValue, daysSinceUpdated })
        }}
        aria-label="Tasks by user"
      >
        {tabIds.map((tabId) => (
          <Tab label={tabs[tabId]} value={tabId} key={tabId} />
        ))}
      </Tabs>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 2,
        }}
      >
        <Typography sx={{ mr: 2 }} variant="caption">
          Showing Tasks from:
        </Typography>
        <DropdownPeriodSelector
          value={daysSinceUpdated.toString()}
          onChangeValue={(value: number) => {
            onChangeFilters({
              context: currentTab,
              daysSinceUpdated: value,
            })
          }}
        />
      </Box>
    </Box>
  )
}

export default TaskFilter
