import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const SignedOut = (): JSX.Element => {
  return (
    <Box mt={20} sx={{ textAlign: 'center' }}>
      <Typography variant="h4">You have been signed out</Typography>
      <Typography variant="h6">Thank you for using LegalFix</Typography>
    </Box>
  )
}

export default SignedOut
