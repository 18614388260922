import {
  type LegalMatterNotesFragment,
  type MutationError,
  type NoteMutationResponse,
  type Note,
  Permission,
} from '../../../@types/generated/graphql'
import { useState, useRef } from 'react'
import NoteItem from '../molecules/NoteItem'
import DotMenu, { type DotMenuAnchor } from '../atoms/DotMenu'
import ConfirmationDialog from '../molecules/ConfirmationDialog'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import log from 'loglevel'
import { orderBy } from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { type serverError } from '../molecules/NoteEditForm'
import LoadingButton from '@mui/lab/LoadingButton'
import useNotes from 'utils/useNotes'
import AclEntity from 'models/AclEntity'
import User from 'models/User'

interface LawyerNotesProps {
  fragment: LegalMatterNotesFragment
  currentUser: User
  disableMenu?: boolean
  onLoadMore?: (endCursor: string) => void
  fetchMoreLoading?: boolean
}

const LawyerNotes = ({
  fragment,
  currentUser,
  disableMenu,
  fetchMoreLoading,
  onLoadMore,
}: LawyerNotesProps): JSX.Element => {
  const noteInUpdateRef = useRef<Note | null>(null)
  const [noteUpdating, setNoteUpdating] = useState<Note | undefined>()
  const [dotMenuAnchor, setDotMenuAnchor] = useState<DotMenuAnchor | null>(null)
  const [serverError, setServerError] = useState<serverError | undefined>()
  const notes = fragment.notes.edges.map((edge) => edge.node)
  const orderedNotes = orderBy(notes, 'createdTimestamp', ['desc'])
  const [notesInEditMode, setNotesInEditMode] = useState<Record<string, Note>>(
    {},
  )
  const { updateNote, deleteNote, noteLoading } = useNotes(fragment.key)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const dotMenuOptions = [
    {
      action: 'editNote',
      label: 'Edit Note',
      icon: <EditIcon />,
      requiredPermission: Permission.NotesUpdate,
    },
    {
      action: 'deleteNote',
      label: 'Delete Note',
      icon: <DeleteIcon />,
      requiredPermission: Permission.NotesDelete,
    },
  ]

  const removeNoteFromEditMode = (noteKey: string): void => {
    const { [noteKey]: _, ...filtered } = notesInEditMode
    setNotesInEditMode(filtered)
  }

  const handleNoteDotMenuClick = (
    event: React.MouseEvent,
    note: Note,
  ): void => {
    const noteAcl = new AclEntity(note.acl)
    setDotMenuAnchor({
      element: event.currentTarget,
      menuItems: dotMenuOptions.map((menuOpt) => ({
        ...menuOpt,
        disabled: !noteAcl.allows(
          currentUser.aclIdentities,
          menuOpt.requiredPermission,
        ),
      })),
    })
  }

  const onClickDotMenuOption = (action: string): void => {
    if (noteInUpdateRef.current !== undefined) {
      const editNoteKey = orderedNotes.findIndex(
        (note) => note.key === noteInUpdateRef.current?.key,
      )
      switch (action) {
        case 'editNote':
          setNotesInEditMode({
            ...notesInEditMode,
            [noteInUpdateRef.current?.key]: orderedNotes[editNoteKey],
          })
          break
        case 'deleteNote':
          setOpenDialog(true)
          break
        default:
      }
    }
  }

  const handleDialogDeleteConfirm = (): void => {
    void deleteNote({
      variables: {
        noteKey:
          noteInUpdateRef.current !== undefined
            ? noteInUpdateRef.current?.key
            : null,
      },
      onCompleted: (data) => {
        if (data.noteDelete.success) {
          enqueueSnackbar('Note was successfully deleted.', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(
            `There was a problem deleting the note: ${data.noteDelete.message}`,
            {
              variant: 'error',
            },
          )
        }
      },
      onError: (error, clientOptions) => {
        setServerError({
          key: clientOptions?.variables?.noteKey,
          message: error.message,
        })
        enqueueSnackbar(
          `There was a problem deleting the note, try again in a few seconds`,
          {
            variant: 'error',
          },
        )
      },
    })
    setOpenDialog(false)
  }

  return (
    <>
      <DotMenu
        anchor={dotMenuAnchor}
        open={Boolean(dotMenuAnchor)}
        onItemSelected={(action) => {
          setDotMenuAnchor(null)
          onClickDotMenuOption(action)
        }}
        onClose={() => {
          setDotMenuAnchor(null)
        }}
      />
      <ConfirmationDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        title={
          <>
            <Typography variant="h1" gutterBottom>
              Are you sure you want to proceed ?
            </Typography>
          </>
        }
        content={
          <>
            <Typography variant="h4" gutterBottom>
              This will permanently delete the Note.
            </Typography>
          </>
        }
        actions={
          <>
            <Button
              onClick={() => {
                setOpenDialog(false)
              }}
              autoFocus
              variant="contained"
              color="error"
              data-testid="confirm-dialog-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialogDeleteConfirm}
              autoFocus
              variant="contained"
              color="primary"
              data-testid="confirm-dialog-btn-accept"
            >
              Confirm
            </Button>
          </>
        }
        errorMessage={serverError?.message}
      />
      {orderedNotes != null
        ? orderedNotes.map((note, i) => (
            <NoteItem
              note={note as Note}
              key={note?.key}
              lawyerName={`${note?.createdByLawyer?.firstName} ${note?.createdByLawyer?.lastName}`}
              isSubmitting={noteLoading && noteUpdating?.key === note.key}
              serverError={serverError}
              isEditing={orderedNotes[i].key in notesInEditMode}
              onClickMenu={(event) => {
                if (disableMenu ?? false) {
                  return
                }
                noteInUpdateRef.current = (note as Note) ?? ''
                handleNoteDotMenuClick(event, note as Note)
              }}
              disableMenu={disableMenu}
              onClickCancelEditButton={(key) => {
                removeNoteFromEditMode(key ?? '')
              }}
              onClickSaveButton={(data) => {
                noteInUpdateRef.current = data.noteKey ?? ''
                void updateNote({
                  variables: {
                    noteKey: data.noteKey ?? '',
                    text: data.noteText,
                  },
                  onCompleted: (data) => {
                    const note = data.noteUpdate as NoteMutationResponse
                    setNoteUpdating(note.note.key)
                    if (data?.noteUpdate?.success !== null) {
                      setServerError(undefined)
                      enqueueSnackbar('Note was successfully updated.', {
                        variant: 'success',
                      })
                      const { [note.note.key]: _, ...filtered } =
                        notesInEditMode
                      setNotesInEditMode(filtered)
                      setNoteUpdating(undefined)
                    } else {
                      const errors = data?.noteUpdate as MutationError
                      log.error('oncompleted update note errors', errors)
                      setServerError({
                        key: note.note.key,
                        message:
                          errors.userErrors[0]?.fieldErrors[0]?.errors[0]
                            ?.message,
                      })
                    }
                  },
                  onError: (error, clientOptions) => {
                    log.info('errors update note', clientOptions)
                    setServerError({
                      key: clientOptions?.variables?.noteKey,
                      message: error.message,
                    })
                  },
                })
              }}
            />
          ))
        : null}

      {fragment.notes.pageInfo.hasNextPage != null &&
        fragment.notes.pageInfo.endCursor != null &&
        onLoadMore != null && (
          <LoadingButton
            loading={fetchMoreLoading}
            disableElevation
            variant="contained"
            color="primary"
            role="button"
            aria-label="Load More"
            onClick={() => {
              onLoadMore(fragment.notes.pageInfo.endCursor!)
            }}
            disabled={fragment.notes.pageInfo.hasNextPage === false}
          >
            Load More
          </LoadingButton>
        )}
    </>
  )
}

export default LawyerNotes
