import { type ErrorResponse } from '@apollo/client/link/error'
import { getGqlErrors } from './graphql-parsing-helpers'
import { GqlError } from '../@types/GqlError'
import { enqueueSnackbar } from 'notistack'

const NOTIFICATION_MAP: Record<GqlError, any> = {
  [GqlError.InternalServerError]: {
    message: 'Something went wrong.',
    options: { variant: 'error' },
  },
  [GqlError.NetworkError]: {
    message: 'Something went wrong with our system. Please try again.',
    options: { variant: 'info', autoHideDuration: 10000 },
  },
  [GqlError.UserNotFound]: {
    message: 'User not found, redirecting to Login page.',
    options: { variant: 'error' },
  },
}

const globalErrorHandler = (error: ErrorResponse): any => {
  let notification
  const code: string = getGqlErrors(error)
  switch (code) {
    case GqlError.UserNotFound:
      notification = NOTIFICATION_MAP[GqlError.UserNotFound]
      break
    case GqlError.NetworkError:
      notification = NOTIFICATION_MAP[GqlError.NetworkError]
      break
    default:
      notification = NOTIFICATION_MAP[GqlError.InternalServerError]
      break
  }

  enqueueSnackbar(notification.message, notification.options)
}

export default globalErrorHandler
