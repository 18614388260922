/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import {
  CursorDirection,
  LawyerLegalMattersListingDocument,
  LegalMatterStatus,
} from '../../../@types/generated/graphql'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { routes } from 'routes'
import LegalMatterListItem from 'view/components/organisms/LegalMatterListItem'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import FiltersLegalMatters from 'view/components/molecules/FiltersLegalMatters'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import ConfirmationAlert from '../atoms/ConfirmationAlert'
import ErrorPage from './ErrorPage'
import { AlertContent } from '../../../@types/DialogControlType'
import {
  type Filter,
  LawyerLegalMatterStatusMap,
  LawyerLegalMatterStatusLabels,
} from 'utils/StatusMapper'
import { EventProviderContext } from 'view/providers/EventProvider'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import { paginationLimits } from '../organisms/PaginateItems'
import LoadingButton from '@mui/lab/LoadingButton'

const LawyerLegalMatterListing = (): JSX.Element => {
  const componentIdentifier = LawyerLegalMatterListing.name
  const { user } = useContext(UserProfileContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()

  const filterName = searchParams.get('filter') ?? 'All'
  const filters: Filter =
    LawyerLegalMatterStatusMap[filterName]?.filter ??
    LawyerLegalMatterStatusMap.All.filter

  const displayAlert =
    location.state === 'REJECTED' ||
    location.state === LegalMatterStatus.Withdrawn

  const handleDropdownChange = (value: string): void => {
    searchParams.set('filter', value)
    navigate(`?${searchParams.toString()}`)
  }

  const { data, networkStatus, error, fetchMore } = useQuery(
    LawyerLegalMattersListingDocument,
    {
      variables: {
        ...filters,
        ...(filters.assignedLawyerRef === null
          ? {
              assignedLawyerRef: null,
            }
          : user != null &&
            filters.status !== LegalMatterStatus.Unassigned && {
              assignedLawyerRef: user.key,
            }),
        limit: paginationLimits.legalMatters,
        cursor: null,
        cursorDirection: CursorDirection.Next,
        calendarEventsLimit: paginationLimits.calendarEvents,
        calendarEventsCursor: null,
        calendarEventsCursorDirection: CursorDirection.Next,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      ['LegalMatterCancelled', 'LegalMatterAssigned', 'LegalMatterShared'],
      [LawyerLegalMattersListingDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const handleOnCloseAlert = (): void => {
    navigate(location.pathname, {})
  }

  if (networkStatus === NetworkStatus.loading) {
    return <LegalMatterListLoading numberOfSkeletons={5} />
  }

  if (error !== undefined) {
    return (
      <ErrorPage content="Sorry, we can not show results, try again later" />
    )
  }

  const onClickLoadMore = async (): Promise<void> => {
    if (data?.legalMatters.pageInfo.endCursor != null) {
      await fetchMore({
        variables: {
          cursor: data.legalMatters.pageInfo.endCursor,
        },
      })
    }
  }

  return (
    <div key="LawyerLegalMatterListing-wrapper">
      <ConfirmationAlert
        open={displayAlert}
        onCloseAlert={handleOnCloseAlert}
        content={AlertContent[location.state]}
      />
      <Typography variant="h1" gutterBottom>
        Attorney Legal Matters
      </Typography>
      <FiltersLegalMatters
        selectedStatus={filterName}
        statusList={LawyerLegalMatterStatusMap}
        onChange={handleDropdownChange}
      ></FiltersLegalMatters>
      <Divider sx={{ mb: 2 }} />
      {data?.legalMatters?.edges?.length === 0 && (
        <Typography gutterBottom>
          There are no legal matters matching this filter state.
        </Typography>
      )}
      {data?.legalMatters?.edges?.map((legalMatter, idx: number) => {
        return (
          <LegalMatterListItem
            key={`legal-matter-item-${legalMatter.node.key as string}`}
            legalMatter={legalMatter.node}
            legalMatterStatusLabels={LawyerLegalMatterStatusLabels}
            textButton={
              legalMatter.node.status === LegalMatterStatus.Assigning ||
              legalMatter.node.status === LegalMatterStatus.Unassigned
                ? 'Review Request'
                : undefined
            }
            onClickButton={() => {
              navigate(
                routes.lawyerLegalMatterDetail.param({
                  legalMatterKey: legalMatter.node.key,
                }),
              )
            }}
            onClickViewDetails={(legalMatter) => {
              navigate(
                routes.lawyerLegalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
          />
        )
      })}
      {data != null && data.legalMatters.pageInfo.hasNextPage && (
        <LoadingButton
          loading={networkStatus === NetworkStatus.fetchMore}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Load More"
          onClick={onClickLoadMore}
          disabled={
            !data.legalMatters.pageInfo.hasNextPage ||
            networkStatus === NetworkStatus.fetchMore
          }
        >
          Load More
        </LoadingButton>
      )}
    </div>
  )
}

export default LawyerLegalMatterListing
