import React from 'react'
import Typography, { type TypographyProps } from '@mui/material/Typography'

interface NameValueLabelProps<C extends React.ElementType> {
  name?: string | undefined
  value?: string | undefined
  separator?: string
  displayDefaultValue?: boolean
  defaultValue?: string
  children?: React.ReactNode
  typographyProps?: TypographyProps<C, { component?: C }>
}

const NameValueLabel = ({
  name,
  value,
  separator = ':',
  displayDefaultValue = true,
  defaultValue = 'N/A',
  typographyProps,
  children,
  ...props
}: NameValueLabelProps<React.ElementType>): JSX.Element => {
  const resolvedValue = resolveNameValueLabel(
    name,
    value,
    separator,
    displayDefaultValue ? defaultValue : undefined,
  )
  return (
    <Typography {...typographyProps} {...props}>
      {resolvedValue ?? null}
      {children ?? null}
    </Typography>
  )
}

export const resolveNameValueLabel = (
  name?: string,
  value?: string,
  separator = ':',
  defaultValue?: string,
): string | undefined => {
  return `${name !== undefined ? name + ' ' + separator + ' ' : ''} ${
    value ?? defaultValue ?? ''
  }`
}

export default NameValueLabel
