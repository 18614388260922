import { UpdateFirmUserMutation } from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const updateFirmUserMutation = gql(/* GraphQL */ `
  mutation updateFirmUser(
    $firmUserKey: Key!
    $formType: JSONFormType!
    $data: [JSON!]!
  ) {
    firmUserUpdate(
      firmUserKey: $firmUserKey
      formType: $formType
      data: $data
    ) {
      ... on FirmUserMutationResponse {
        code
        success
        message
        user {
          ... on LawyerUser {
            email
            firstName
            gender
            key
            lastName
            location {
              state {
                name
              }
              city
              street
              zipCode
            }
            middleName
            phone
            firm {
              key
              name
            }
            practiceAreas {
              key
              category
              value
            }
          }
          ... on FirmUser {
            email
            firstName
            gender
            key
            lastName
            location {
              state {
                name
              }
              city
              street
              zipCode
            }
            middleName
            phone
            firm {
              key
              name
            }
          }
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

interface FirmUserHook {
  updateFirmUser: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateFirmUserMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateFirmUserMutation>>
  firmUserLoading: boolean
  firmUserError: any
}

const useFirmUser = (): FirmUserHook => {
  const [updateFirmUser, { loading: firmUserLoading, error: firmUserError }] =
    useMutation<UpdateFirmUserMutation>(updateFirmUserMutation)

  return {
    updateFirmUser,
    firmUserLoading,
    firmUserError,
  }
}

export default useFirmUser
