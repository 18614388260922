import { NetworkStatus, useQuery } from '@apollo/client'
import { gql } from '../../../@types/generated/gql'
import { LegalMatterStatus } from '../../../@types/generated/graphql'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes } from 'routes'

import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import LegalMatterListItem from 'view/components/organisms/LegalMatterListItem'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import FiltersLegalMatters from 'view/components/molecules/FiltersLegalMatters'
import ErrorPage from './ErrorPage'
import {
  type Filter,
  FirmLegalMatterStatusMap,
  FirmLegalMatterStatusLabels,
} from 'utils/StatusMapper'
import { useContext, useEffect } from 'react'
import { EventProviderContext } from '../../providers/EventProvider'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import {
  CursorDirection,
  FirmLegalMatterListingDocument,
} from '../../../@types/generated/graphql'
import { paginationLimits } from '../organisms/PaginateItems'
import LoadingButton from '@mui/lab/LoadingButton'

const FirmLegalMatterListing = (): JSX.Element => {
  const componentIdentifier = FirmLegalMatterListing.name
  const navigate = useNavigate()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'LegalMatterCreated',
        'LegalMatterAccepted',
        'LegalMatterRejected',
        'LegalMatterShared',
      ],
      [FirmLegalMatterListingDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const [searchParams] = useSearchParams()
  const filterName = searchParams.get('filter') ?? 'All'
  const filters: Filter =
    FirmLegalMatterStatusMap[filterName]?.filter ??
    FirmLegalMatterStatusMap.All?.filter

  const handleFilterChange = (value: string): void => {
    searchParams.set('filter', value)
    navigate(`?${searchParams.toString()}`)
  }

  const { data, networkStatus, error, fetchMore } = useQuery(
    FirmLegalMatterListingDocument,
    {
      variables: {
        ...filters,
        limit: paginationLimits.legalMatters,
        cursor: null,
        cursorDirection: CursorDirection.Next,
        calendarEventsLimit: paginationLimits.calendarEvents,
        calendarEventsCursor: null,
        calendarEventsCursorDirection: CursorDirection.Next,
      },
      notifyOnNetworkStatusChange: true,
      pollInterval: 10000,
    },
  )

  if (networkStatus === NetworkStatus.loading) {
    return <LegalMatterListLoading numberOfSkeletons={5} />
  }

  if (data === undefined) {
    return <div>No data</div>
  }

  if (error !== undefined) {
    return (
      <ErrorPage content="Sorry, we can not show results, try again later" />
    )
  }

  const onClickLoadMore = async (): Promise<void> => {
    if (data?.legalMatters.pageInfo.endCursor != null) {
      await fetchMore({
        variables: {
          cursor: data?.legalMatters.pageInfo.endCursor,
        },
      })
    }
  }

  return (
    <div>
      <Typography variant="h1" gutterBottom>
        Firm legal matter listing
      </Typography>
      <FiltersLegalMatters
        selectedStatus={filterName}
        statusList={FirmLegalMatterStatusMap}
        onChange={handleFilterChange}
      ></FiltersLegalMatters>
      <Divider sx={{ mb: 2 }} />
      {data?.legalMatters?.edges?.length === 0 && (
        <Typography gutterBottom>
          There are no legal matters matching this filter state.
        </Typography>
      )}
      {data?.legalMatters?.edges?.map((legalMatter, idx: number) => {
        return (
          <LegalMatterListItem
            key={`legal-matter-item-${idx}`}
            data-testid="legal-matter-item"
            legalMatter={legalMatter.node}
            legalMatterStatusLabels={FirmLegalMatterStatusLabels}
            textButton={
              legalMatter?.node.status === LegalMatterStatus.Unassigned
                ? 'Assign an Attorney'
                : undefined
            }
            onClickButton={(legalMatter) => {
              navigate(
                routes.firmLegalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
            onClickViewDetails={(legalMatter) => {
              navigate(
                routes.firmLegalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
          />
        )
      })}
      {data != null && (
        <LoadingButton
          loading={networkStatus === NetworkStatus.fetchMore}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Load More"
          onClick={onClickLoadMore}
          disabled={!data.legalMatters.pageInfo.hasNextPage}
        >
          Load More
        </LoadingButton>
      )}
    </div>
  )
}

export default FirmLegalMatterListing
