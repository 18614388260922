import { useContext } from 'react'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import { signOut } from 'firebaseConfig'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Navbar from 'view/components/organisms/Navbar'
import { Outlet } from 'react-router-dom'
import { routes } from 'routes'
import AvatarMenu from '../molecules/AvatarMenu'
import AnonymousUser from 'models/AnonymousUser'
import SubscriberUser from 'models/SubscriberUser'
import useAccessRules from 'utils/useAccessRules'
import ConnectionMonitor from '../molecules/ConnectionMonitor'
import DebugRouter from 'view/components/atoms/DebugRouter'
import GaTracker from '../molecules/GaTracker'

const PageLayout = (): JSX.Element => {
  const { user } = useContext(UserProfileContext)
  const { userIsOneOf, userHasRole } = useAccessRules(user)

  const userRoutes = Object.values(routes).filter(
    (route) =>
      route.navbar === true &&
      (route.allowedUserTypes != null
        ? userIsOneOf(route.allowedUserTypes)
        : true) &&
      (route.requiresRole != null ? userHasRole(route.requiresRole) : true),
  )

  return (
    <Box pt={8}>
      <GaTracker />
      <Navbar
        items={userRoutes}
        avatarMenu={
          !(user instanceof AnonymousUser) ? (
            <AvatarMenu
              onSignOut={() => {
                void signOut().then(() => {
                  window.location.replace(routes.signedOut.path)
                })
              }}
              avatarSrc={
                user.profile.photoURL ?? user.profile.displayName ?? null
              }
              hasSubscriberProfile={user instanceof SubscriberUser}
            />
          ) : null
        }
      />
      <ConnectionMonitor />
      <Container maxWidth="xl">
        <DebugRouter />
        <Outlet />
      </Container>
    </Box>
  )
}

export default PageLayout
