import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIcon from '@mui/icons-material/Phone'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import SeparationGridBox from 'view/components/atoms/SeparationGridBox'
import { resolveSubscriberName } from 'utils/StringFormatter'
import NameValueLabel, {
  resolveNameValueLabel,
} from 'view/components/atoms/NameValueLabel'

interface LawFirmProps {
  lawyerName?: string
  lawyerLastName?: string
  lawyerEmail?: string
  lawyerPhone?: string
  firmName?: string
  firmEmail?: string
  firmPhone?: string
}

const LawFirmContact = ({
  lawyerName,
  lawyerLastName,
  lawyerEmail,
  lawyerPhone,
  firmName,
  firmEmail,
  firmPhone,
}: LawFirmProps): JSX.Element => {
  return (
    <SeparationGridBox>
      <Grid item>
        <Typography gutterBottom variant="subtitle1">
          Provider Law Firm:
        </Typography>
        <Button
          variant="text"
          size="small"
          startIcon={<ApartmentOutlinedIcon />}
          sx={{ textTransform: 'none' }}
        >
          <NameValueLabel
            value={firmName}
            typographyProps={{ gutterBottom: true, variant: 'h4' }}
          />
        </Button>
        <Typography variant="h4">
          <MailOutlineIcon fontSize="small" />
          {firmEmail !== undefined ? (
            <Link
              variant="overline"
              data-testid="document-uploaded"
              href={`mailto:${String(firmEmail)}`}
            >
              {firmEmail}
            </Link>
          ) : (
            resolveNameValueLabel(firmEmail)
          )}
        </Typography>
        <Typography gutterBottom variant="h4">
          <PhoneIcon fontSize="small" />
          {firmPhone !== undefined ? (
            <Link
              variant="overline"
              data-testid="document-uploaded"
              href={`tel:${String(firmPhone)}`}
            >
              {firmPhone}
            </Link>
          ) : (
            resolveNameValueLabel(firmPhone)
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography gutterBottom variant="subtitle1">
          Contact Person
        </Typography>
        <Button
          variant="text"
          size="small"
          startIcon={<PersonOutlineIcon fontSize="small" />}
          sx={{ textTransform: 'none' }}
        >
          <NameValueLabel
            value={resolveSubscriberName(lawyerName, lawyerLastName)}
            typographyProps={{ gutterBottom: true, variant: 'h4' }}
          />
        </Button>
        <Typography variant="h4">
          <MailOutlineIcon fontSize="small" />
          {lawyerEmail !== undefined ? (
            <Link
              variant="overline"
              data-testid="document-uploaded"
              href={`mailto:${String(lawyerEmail)}`}
            >
              {lawyerEmail}
            </Link>
          ) : (
            resolveNameValueLabel(lawyerEmail)
          )}
        </Typography>
        <Typography gutterBottom variant="h4">
          <PhoneIcon fontSize="small" />
          {lawyerPhone !== undefined ? (
            <Link
              variant="overline"
              data-testid="document-uploaded"
              href={`tel:${String(lawyerPhone)}`}
            >
              {lawyerPhone}
            </Link>
          ) : (
            resolveNameValueLabel(lawyerPhone)
          )}
        </Typography>
      </Grid>
    </SeparationGridBox>
  )
}

export default LawFirmContact
