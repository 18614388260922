import { ProductInterval } from '../../../@types/generated/graphql'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { priceCaption } from 'utils/StringFormatter'

interface CheckoutBarProps {
  cartTotals: Record<ProductInterval | 'oneTime', number>
  requestInProgress: boolean
  isUserOnSupportedState: boolean
  onOptionSelected: (option: ProductInterval) => void
}
const CheckoutBar = ({
  cartTotals,
  requestInProgress,
  isUserOnSupportedState,
  onOptionSelected,
}: CheckoutBarProps): JSX.Element => {
  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      direction={{ sx: 'column', sm: 'row' }}
      spacing={4}
      sx={{
        backgroundColor: 'primary.main',
        position: 'relative',
        my: 5,
        py: 2,
        pl: 4,
      }}
    >
      {requestInProgress ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <CircularProgress color="error" />
          <Typography variant="h5" sx={{ color: 'white' }}>
            Processing, please wait
          </Typography>
        </Stack>
      ) : (
        <Typography variant="h5" sx={{ color: 'white' }}>
          Your subscription total
        </Typography>
      )}
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        disabled={
          cartTotals[ProductInterval.Month] == null ||
          requestInProgress ||
          !isUserOnSupportedState
        }
        onClick={() => {
          onOptionSelected(ProductInterval.Month)
        }}
        sx={{
          '&.Mui-disabled': {
            borderColor: '#d7d4d4',
            backgroundColor: '#d7d4d4',
            color: (theme) => theme.palette.primary.main,
          },
          marginBottom: {
            xs: 1,
          },
        }}
      >
        {cartTotals[ProductInterval.Month] != null
          ? priceCaption([
              {
                recurringInterval: ProductInterval.Month,
                unitAmount: cartTotals[ProductInterval.Month],
              },
            ])
          : 'Price not available'}
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        disabled={
          cartTotals[ProductInterval.Year] == null ||
          requestInProgress ||
          !isUserOnSupportedState
        }
        onClick={() => {
          onOptionSelected(ProductInterval.Year)
        }}
        sx={{
          '&.Mui-disabled': {
            borderColor: '#d7d4d4',
            backgroundColor: '#d7d4d4',
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        {cartTotals[ProductInterval.Year] != null
          ? priceCaption([
              {
                recurringInterval: ProductInterval.Year,
                unitAmount: cartTotals[ProductInterval.Year],
              },
            ])
          : 'Price not available'}
      </Button>
    </Stack>
  )
}

export default CheckoutBar
