import { useParams, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { snakeCase } from 'lodash'
import { isDateInThePast } from 'utils/dateUtils'
import { useFirmInvite } from 'utils/useFirmInvite'

const FirmInvite = (): JSX.Element => {
  const navigate = useNavigate()
  const { inviteKey } = useParams()
  const { data } = useFirmInvite({
    variables: {
      inviteRef: inviteKey,
    },
  })

  if (isDateInThePast(data?.firmsUserInvite.expiresAt)) {
    return (
      <Alert severity="error" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-state-notice">
          Sorry, the invitation expired. Contact LegalFix team with any
          questions.
        </Typography>
      </Alert>
    )
  }

  return (
    <Box>
      <Typography variant="h1" sx={{ mt: 8 }}>
        Invite to join LegalFix!
      </Typography>
      <Typography variant="body1" sx={{ mt: 8, mb: 6 }} gutterBottom>
        You've been invited to join {data?.firmsUserInvite.firm.name} in
        LegalFix.
      </Typography>
      <Typography variant="body1" gutterBottom>
        {data?.firmsUserInvite.email} have been invited to join{' '}
        {data?.firmsUserInvite.firm.name} team to start fielding request for
        clients seeking legal services.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate(
            `/firms/sign-up/${snakeCase(data?.firmsUserInvite.userType)}/${inviteKey!}`,
          )
        }}
        sx={[
          {
            backgroundColor: 'primary.main',
            color: 'white',
            fontWeight: 700,
            border: 2,
            borderRadius: '2px',
            lineHeight: '22px',
            textAlign: 'center',
            padding: '10px 25px',
            marginTop: '10px',
          },
          {
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'white',
              border: 2,
            },
          },
        ]}
      >
        Join LegalFix
      </Button>
      <Typography variant="subtitle1" sx={{ mt: 4 }}>
        We are excited for you to join LegalFix
      </Typography>
      <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
        Neal - CEO of LegalFix
      </Typography>
    </Box>
  )
}

export default FirmInvite
