import React, { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { type UserCredentials } from 'firebaseConfig'
import { type Error } from '../pages/Auth'
import GoogleIcon from '@mui/icons-material/Google'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

interface Props {
  onSubmitLoginForm?: (data: UserCredentials) => void
  onGoogleSignIn?: () => void
  isLogging?: boolean
  errors?: Error
  onHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Login = ({
  onSubmitLoginForm,
  onGoogleSignIn,
  isLogging = false,
  errors,
  onHandleChange,
}: Props): JSX.Element => {
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(true)
  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors },
  } = useForm<UserCredentials>()

  const fields = {
    email: register('email', {
      required: { value: true, message: 'Email is required' },
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 80,
    }),
    password: register('password', {
      required: { value: true, message: 'Password is required' },
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 20,
    }),
  }

  const onSubmit: SubmitHandler<UserCredentials> = (data) => {
    onSubmitLoginForm?.(data)
  }

  return (
    <>
      <Typography
        justifyContent="center"
        variant="h1"
        align="center"
        gutterBottom
      >
        Login
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="signin-form">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{ px: 2 }}>
            <TextField
              label="Email"
              id="email"
              size="small"
              {...fields.email}
              fullWidth
              error={!(validationErrors?.email === undefined)}
              helperText={validationErrors?.email?.message}
              onChange={(ev) => {
                if (ev.target.value !== '') {
                  setIsEmailEmpty(false)
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ px: 2 }}>
            <TextField
              label="Password"
              id="password"
              size="small"
              type="password"
              {...fields.password}
              disabled={isEmailEmpty}
              fullWidth
              onChange={onHandleChange}
              error={
                errors?.message === 'Wrong password.' ? errors?.isError : false
              }
              helperText={
                errors?.message === 'Wrong password.' ? errors?.message : ''
              }
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ px: 2, pt: 2 }}
          >
            <Grid item xs={12}>
              <LoadingButton
                loading={isLogging}
                variant="contained"
                type="submit"
                data-testid="btn-signup"
                sx={{ height: '48px' }}
                fullWidth
              >
                Sign In
              </LoadingButton>
              <Divider sx={{ py: 2 }}>OR</Divider>
              <Button
                startIcon={<GoogleIcon color="info" />}
                variant="outlined"
                onClick={onGoogleSignIn}
                data-testid="btn-googleSignIn"
                sx={{ width: '100%' }}
              >
                {' '}
                Sign in with Google{' '}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default Login
